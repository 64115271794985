import React from 'react'
import Portfolio from "../components/Portfolio.js"
import ShopifyLogo from "../img/ShopifyLogo.svg"


export default function PortfolioPage(){
  return(
    <>
   <Portfolio    
    pageTitle={<span><img width="150px" style={{"verticalAlign":"middle","marginTop":"-20px"}} src={ShopifyLogo}/> <span style={{"color":"#95BF47"}}>+</span> John Dilworth</span>}
    pageIntro="I’m John Dilworth, and I would love to work with Shopify in the role of  UX Director, Online Store Tools."
    pageIntroSize="1.5em"
    backSplash="#f4f6f8"
    backSplashAlt="#5E8E3E"
    pageTitleColor="#000"
    pageTitleSize="3em"    
    introTextColor="#000"
    introTextSize="1.5em"
    introTextLineHeight="1.25em"    
    textColor="#000"
    textColorAlt="#5E8E3E"
    textSizeLarge="3em"
    textSizeMedium="1.5em"
    textSizeSmall="1em"
    textColor=""
    textLineHeight=""
    coverLetter={
      <div>
        <p>I have helped to hire, grow and manage 30+ person design teams. I directly oversaw a group of 20 designers, researchers, and prototypers (some remote, including myself).</p>        
        <p>I have worked closely with engineering and managed extensive design systems and front-end development teams.</p>
        <p>I love helping others do their best, and would like to bring my talent and experience to Shopify.</p>      
      </div>
    }
    coverBullets={
      <ul>
        <li>20+ years experience</li>
        <li>Nine years of experience in management roles up to 20 employees</li>
        <li>I know how to hire great talent</li>
        <li>Experience working in-house design and with a design agency</li>
        <li>6+ years successfully working as a remote employee</li>
        <li>Deep understanding of design process and software and mobile development</li>
        <li>I can design, code, write and am happy to do any of it</li>
        <li>I want to build amazing products and work with great people</li>        
      </ul>
    }
   />

     
   </>
  )
}


export const PortfolioPageQuery = graphql`
  query PortfolioPageQuery {
    articles:allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: "ML" }, published: {ne: false} } }
      limit: 4
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          timeToRead
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            description
            date(formatString: "MMMM DD, YYYY")
            path
            thumb{
              childImageSharp{
                fluid(maxWidth: 500){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    trips:allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date, frontmatter___title]},
      filter: { frontmatter: { templateKey: { eq: "trip" } }},
      limit: 8
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            thumb{
              childImageSharp{
                fluid(maxWidth: 500){
                  ...GatsbyImageSharpFluid
                }
              }
            }
            templateKey
            date(formatString: "MMMM DD, YYYY")
            path
          }
        }
      }
    }
    artwork:allMarkdownRemark(
        sort: { order: ASC, fields: [frontmatter___order]},
        filter: { frontmatter: { templateKey: { eq: "artwork" }, collection: { eq: "inventors" } }},
        limit: 1
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              description
              templateKey
              date(formatString: "MMMM DD, YYYY")
              path
              thumb{
                childImageSharp{
                  fluid(maxWidth: 500){
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
  }
  `